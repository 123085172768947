


























































import { Component, Watch } from "vue-property-decorator";
import FscPageHeader from "@/components/FscPageHeader.vue";
import FscSimpleCard from "@/components/Card/FscSimpleCard.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import SaveButton from "@/components/Button/SaveButton.vue";
import PaymentStudents from "@/views/AutomaticPaymentsOverview/PaymentStudents.vue";
import { IStudent } from "@/interfaces/IStudent";
import { mixins } from "vue-class-component";
import TabMixin from "@/mixins/TabMixin";
import HintMessage from "@/views/AutomaticPaymentsOverview/HintMessage.vue";
import { namespace } from "vuex-class";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import { IPaymentWorkflowStudent } from "@/interfaces/IPaymentWorkflowStudent";

const AppModule = namespace("app");
const CostBearerPaymentRequestModule = namespace("cost-bearer-payment-request");

@Component({
  components: {
    HintMessage,
    PaymentStudents,
    SaveButton,
    AbortButton,
    FscPageHeader,
    FscSimpleCard,
  },
})
export default class CostBearerPaymentRequest extends mixins(TabMixin) {
  public name = "CostBearerPaymentRequest";

  @AppModule.Action("setSelectedCostBearerClassicPayStudents")
  public setSelectedCostBearerClassicPayStudents!: (data: Array<any>) => void;

  @AppModule.Getter("getSelectedCostBearerClassicPayStudents")
  protected getSelectedCostBearerClassicPayStudents!: Array<IPaymentWorkflowStudent>;

  @CostBearerPaymentRequestModule.Action("create")
  protected costBearerPaymentRequestCreate!: (options: ICrudOptions) => Promise<void>;

  @CostBearerPaymentRequestModule.Getter("getSuccess")
  protected costBearerPaymentRequestSuccess!: boolean;

  protected selectedClassicPayStudents: Array<any> = [];

  protected studentPaymentRequests: Array<any> = [];
  protected customBookingPaymentRequests: Array<any> = [];

  protected onDeleteStudent(item: IStudent, index: number): void {
    this.selectedClassicPayStudents.splice(index, 1);
  }

  protected async onSubmit(): Promise<void> {
    const studentRequests = this.selectedClassicPayStudents.filter((student: any) => student.studentEducationId !== null);
    this.studentPaymentRequests = studentRequests.map((stp: any) => {
      return {
        studentEducationId: stp.studentEducationId,
        amountInEur: stp.amountInEur,
      };
    });
    const customRequests = this.selectedClassicPayStudents.filter((custom: any) => custom.costBearerCustomBookingId !== null);
    this.customBookingPaymentRequests = customRequests.map((cpr: any) => {
      return {
        costBearerCustomBookingId: cpr.costBearerCustomBookingId,
        amountInEur: cpr.amountInEur,
      };
    });
    await this.costBearerPaymentRequestCreate({
      resource: "/cost-bearer-payment-requests",
      data: {
        costBearerId: Number(this.$route.params.costBearerId),
        studentPaymentRequests: this.studentPaymentRequests ? this.studentPaymentRequests : null,
        customBookingPaymentRequests: this.customBookingPaymentRequests ? this.customBookingPaymentRequests : null,
      },
    });

    if (this.costBearerPaymentRequestSuccess) {
      this.$toasted.success("Success!");
      this.closeCurrentTab();
    }
  }

  public mounted(): void {
    this.initSelectedClassicPayStudents();
  }

  protected initSelectedClassicPayStudents(): void {
    this.selectedClassicPayStudents = [];
    this.getSelectedCostBearerClassicPayStudents.forEach((paymentStudent: any) => {
      this.selectedClassicPayStudents.push({
        studentEducationId: paymentStudent.studentEducationId,
        costBearerCustomBookingId: paymentStudent.costBearerCustomBookingId,
        student: paymentStudent.student,
        description: paymentStudent.description,
        costBearerId: Number(this.$route.params.costBearerId),
        amountInEur: paymentStudent.actualBalance.saldo < 0 ? Math.abs(paymentStudent.actualBalance.saldo) : null,
      });
    });
    if (this.selectedClassicPayStudents.length === 0) {
      this.$nextTick(() => {
        this.closeCurrentTab();
      });
    }
  }

  @Watch("getSelectedCostBearerClassicPayStudents")
  protected getSelectedClassicPayStudentsWatcher(): void {
    this.initSelectedClassicPayStudents();
  }

  public beforeDestroy(): void {
    this.$root.$emit("before-destroyed-component", {
      component: "CostBearerClassicPay",
      success: this.costBearerPaymentRequestSuccess,
    });
    this.setSelectedCostBearerClassicPayStudents([]);
  }

  protected onAbort(): void {
    this.closeCurrentTab();
  }
}
